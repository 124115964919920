<template>
  <span>
    {{ patientName }}
  </span>
</template>

<script>
export default {
  props: {
    patient: {
      type: Object,
      default: () => ({
        first_name: '',
        middle_initial: '',
        last_name: '',
        suffix: '',
      }),
    },
  },
  computed: {
    patientName() {
      const name = `${(this.patient.first_name || '')} ${(this.patient.middle_initial || '')} ${(this.patient.last_name || '')} ${(this.patient.suffix || '')}`
        .trim()

      return name || this.patient.id
    },
  },
}
</script>
