<template>
  <span>
    <v-icon
      class="patient-info-gender"
      :class="patient.gender && patient.gender.toLowerCase()"
      dense
    >
      {{ genderIcon }}
    </v-icon>
    DOB: {{ dobUS }} ({{ patientAge }}) {{ patientSSN }}
  </span>
</template>

<script>
import {
  mdiGenderFemale, mdiGenderMale, mdiGenderMaleFemale, mdiAccountQuestion,
} from '@mdi/js'

export default {
  props: {
    patient: {
      type: Object,
      default: () => ({
        dob: '',
        gender: '',
        ssn_last_4: '',
      }),
    },
  },
  data() {
    return {
      icons: {
        mdiGenderMale, mdiGenderFemale, mdiGenderMaleFemale, mdiAccountQuestion,
      },
    }
  },
  computed: {
    dobUS() {
      return this.patient.dob ? this.$date(this.patient.dob).format('MM/DD/YYYY') : '??/??/??'
    },
    patientAge() {
      return this.$custom.getAgeDescription(this.patient.dob)
    },
    patientSSN() {
      return this.patient.ssn_last_4 && this.patient.ssn_last_4.length === 4 ? ` - SSN: ${this.patient.ssn_last_4}` : ''
    },
    genderIcon() {
      switch (this.patient.gender) {
        case 'Male': return this.icons.mdiGenderMale
        case 'Female': return this.icons.mdiGenderFemale
        case 'Male-Female': return this.icons.mdiGenderMaleFemale
        case 'Unknown': return this.icons.mdiAccountQuestion
        default: return null
      }
    },
  },
}
</script>

<style lang="scss">
.v-icon.patient-info-gender {
  vertical-align: text-bottom;
  &.male {
    color: #2aa3fb;
  }
  &.female {
    color: #f77389;
  }
}
</style>
