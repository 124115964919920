<template>
  <v-row>
    <Comments />
  </v-row>
</template>

<script>
import Comments from '@/components/features/Comments.vue'

export default {
  components: { Comments },
}
</script>
