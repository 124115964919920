import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VExpandTransition,[_c(VCard,_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(!_vm.isRemoved),expression:"!isRemoved"}]},'v-card',_vm.$attrs,false),_vm.$listeners),[_c(VCardTitle,[_vm._t("title"),_c(VSpacer),_c('div',[_vm._t("before-actions"),_c(VTooltip,{attrs:{"open-on-click":false,"bottom":"","disabled":!_vm.tooltip},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,{directives:[{name:"show",rawName:"v-show",value:((!(_vm.actionRefresh || _vm.actionRemove) || _vm.actionCollapse) && !_vm.noActions),expression:"(!(actionRefresh || actionRemove) || actionCollapse) && !noActions"}],attrs:{"icon":"","small":""},on:{"click":_vm.triggerCollapse}},[_c(VIcon,_vm._g(_vm._b({style:({ transform: _vm.isContentCollapsed ? 'rotate(-180deg)' : null }),attrs:{"size":"20"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiChevronUp)+" ")])],1)]}}])},[_c('span',[_vm._v("Collapse this section")])]),_c(VTooltip,{attrs:{"open-on-click":false,"bottom":"","disabled":!_vm.tooltip},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,{directives:[{name:"show",rawName:"v-show",value:((!(_vm.actionCollapse || _vm.actionRemove) || _vm.actionRefresh) && !_vm.noActions),expression:"(!(actionCollapse || actionRemove) || actionRefresh) && !noActions"}],attrs:{"icon":"","small":""},on:{"click":_vm.triggerRefresh}},[_c(VIcon,_vm._g(_vm._b({attrs:{"size":"20"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiSync)+" ")])],1)]}}])},[_c('span',[_vm._v("Refresh")])]),_c(VTooltip,{attrs:{"open-on-click":false,"bottom":"","disabled":!_vm.tooltip},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,{directives:[{name:"show",rawName:"v-show",value:((!(_vm.actionCollapse || _vm.actionRefresh) || _vm.actionRemove) && !_vm.noActions),expression:"(!(actionCollapse || actionRefresh) || actionRemove) && !noActions"}],attrs:{"icon":"","small":""},on:{"click":_vm.triggerRemove}},[_c(VIcon,_vm._g(_vm._b({attrs:{"size":"20"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiClose)+" ")])],1)]}}])},[_c('span',[_vm._v("Close this section")])]),_vm._t("after-actions")],2)],2),_c(VExpandTransition,[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isContentCollapsed),expression:"!isContentCollapsed"}]},[_vm._t("default")],2)]),_c(VOverlay,{attrs:{"absolute":"","color":"white","opacity":"0.75"},model:{value:(_vm.showOverlay),callback:function ($$v) {_vm.showOverlay=$$v},expression:"showOverlay"}},[_c(VProgressCircular,{attrs:{"indeterminate":"","color":"primary"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }